exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accountants-bookkeepers-js": () => import("./../../../src/pages/accountants-bookkeepers.js" /* webpackChunkName: "component---src-pages-accountants-bookkeepers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-js": () => import("./../../../src/pages/integrations.js" /* webpackChunkName: "component---src-pages-integrations-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-whats-new-js": () => import("./../../../src/pages/whats-new.js" /* webpackChunkName: "component---src-pages-whats-new-js" */),
  "component---src-templates-template-blog-js": () => import("./../../../src/templates/template-blog.js" /* webpackChunkName: "component---src-templates-template-blog-js" */),
  "component---src-templates-template-business-js": () => import("./../../../src/templates/template-business.js" /* webpackChunkName: "component---src-templates-template-business-js" */),
  "component---src-templates-template-features-js": () => import("./../../../src/templates/template-features.js" /* webpackChunkName: "component---src-templates-template-features-js" */),
  "component---src-templates-template-integrations-js": () => import("./../../../src/templates/template-integrations.js" /* webpackChunkName: "component---src-templates-template-integrations-js" */),
  "component---src-templates-template-landing-js": () => import("./../../../src/templates/template-landing.js" /* webpackChunkName: "component---src-templates-template-landing-js" */),
  "component---src-templates-template-pages-js": () => import("./../../../src/templates/template-pages.js" /* webpackChunkName: "component---src-templates-template-pages-js" */),
  "component---src-templates-template-paginated-js": () => import("./../../../src/templates/template-paginated.js" /* webpackChunkName: "component---src-templates-template-paginated-js" */)
}

